<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">报名列表（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">报名列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                v-model="realname"
                type="text"
                size="small"
                clearable
                placeholder="请输入学员姓名"
              />
            </div>
            <div title="是否已联系" class="searchboxItem ci-full">
              <span class="itemLabel">是否已联系:</span>
              <el-select
                  clearable
                  size="small"
                  v-model="isContact"
                  placeholder="请选择"
              >
                <el-option
                    v-for="item in isContactList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button
              style="margin-left:20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <div class="btnBox" style="margin-left:20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="学员姓名"
                align="center"
                prop="realname"
                show-overflow-tooltip
                min-width="100"
              />

              <el-table-column
                  label="性别"
                  align="center"
                  prop="sex"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="scope">
                  {{scope.row.sex == '1'? '男':'女'}}
                </template>
              </el-table-column>
              <el-table-column
                label="工作单位"
                align="center"
                prop="company"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="所属岗位"
                align="center"
                prop="post"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="行政区划"
                align="center"
                prop="areaName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="地址"
                align="center"
                prop="address"
                show-overflow-tooltip
                min-width="100"
              />       
              <el-table-column
                label="联系方式"
                align="center"
                prop="phone"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="报名时间"
                align="center"
                prop="applyTime"
                show-overflow-tooltip
                min-width="100"
              >
              <template slot-scope="scope">{{
                  scope.row.applyTime | moment}}</template>
              </el-table-column>

              <el-table-column
                label="是否联系"
                align="center"
                width="320px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-switch
                    :width="20"
                    v-model="scope.row.isContact"
                    @change="
                      getSwitchUsable(scope.row.isContact, scope.row.id)
                    "
                  ></el-switch>
                  <span>{{ scope.row.isContact ? "是" : "否" }}</span>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "teacherList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogFormVisible: false,
      realname: "",
      isContact: null,
      options: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      isContactList:[
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ]
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        realname: this.realname,
        isContact: this.isContact,
      };
      this.doFetch(
        {
          url: "/miniapp/com/apply/list",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    /* 是否联系 */
    getSwitchUsable(keyId, enabled) {
      this.$post(
        "/miniapp/com/apply/modifyIsContact",
        {
          isContact: keyId,
          id: enabled,
        },
        3000,
        false,
        2
      )
        .then((ret) => {
          this.$message({
            type: "success",
            message: ret.message,
          });
          this.getData(-1);
        })
        .catch((err) => {
          return;
        });
    },
    //新增
    handleCreate(stu, courseId = "") {
      let params = {
        isContact: this.isContact,
        realname: this.realname
      };
      this.$post("/miniapp/com/apply/list/export", params, 3000, true, 2).then(
          res => {
            if (res.status == "0") {
              window.location.href = res.data;
            }
          }
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refrsh == true) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
</style>
<style lang="less" scoped></style>
